
import { Vue, Component } from 'vue-property-decorator'
import { ProjectDeviceList, SoilHumidityDetail, DeviceInfo } from '@/types/ecologyMonitor'
import { PolygonPathDetail } from '@/types/common'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import videojs from 'video.js'

require('video.js/dist/video-js.css')
@Component
export default class SoilMonitor extends Vue {
  // 折叠面板当前展开项
  private activeName = 0
  // 当前地图展示项目
  private projectId = ''
  // 项目列表
  private projectDeviceList: ProjectDeviceList[] = []
  // 当前项目设备列表
  private deviceList = []
  private projectLocation: Array<PolygonPathDetail> = []
  private drawer = false
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []
  private onlineImg = require('@/assets/icon/monitor/online.svg')
  private onlineImgCursor = require('@/assets/icon/monitor/online2.svg')
  private offImg = require('@/assets/icon/monitor/off.svg')
  private offImgCursor = require('@/assets/icon/monitor/off2.svg')

  private drawerData: SoilHumidityDetail | null = null
  private videoPlay: any = null
  private videoHtml =''

  created () {
    this.getData()
  }

  destroyed () {
    this.clearVideo()
    if (this.markers) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.destroy()
    }
  }

  // 左侧项目列表
  getData () {
    this.$axios.get(this.$apis.secureRiskMonitor.selectProjectCameraDemoByList).then(res => {
      this.projectDeviceList = res || []
      if (this.projectDeviceList.length > 0) {
        this.projectId = this.projectDeviceList[0].projectId
        this.getPloygon()
      } else {
        this.$nextTick(() => {
          this.initMap(116.4017, 39.9)
        })
      }
    })
  }

  // 切换项目
  changeProject () {
    const id = this.projectDeviceList[Number(this.activeName)].projectId
    if ((this.activeName || this.activeName === 0) && this.projectId !== id) {
      this.projectId = id
      this.getPloygon()
      this.drawerClose()
    }
  }

  // 获取项目围栏
  getPloygon () {
    this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
      projectId: this.projectId
    }).then((res) => {
      if (res) {
        this.projectLocation = res.locationList || []
        const lng = Number(res.longitude)
        const lat = Number(res.latitude)
        this.$nextTick(() => {
          this.initMap(lng, lat)
        })
      } else {
        this.$message.error('未获取到项目信息')
      }
    })
  }

  getDeviceList () {
    this.$axios.get(this.$apis.secureRiskMonitor.selectCameraDemoInfoByPage, {
      projectId: this.projectId
    }).then(res => {
      this.deviceList = res.list || []
      this.drawMarker(this.deviceList)
    })
  }

  // 初始化地图
  initMap (lng: number, lat: number) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [lng, lat]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, this.projectLocation || [], null, this.drawerClose)
      this.getDeviceList()

      // 点击地图关闭抽屉
      this.map.on('click', this.drawerClose)
    })
  }

  // 地图打点
  drawMarker (list: Array<any>) {
    this.markers = []
    if (list && list.length) {
      // 循坏将设备marker添加到地图
      list.forEach((item: SoilHumidityDetail) => {
        if (item.longitude && item.latitude) {
          const icon = this.getIcon(false)
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
          })
          marker.detail = item
          marker.on('click', this.drawerOpen)
          this.map && this.map.add(marker)
          this.markers.push(marker)
        }
      })
    }
  }

  // 切换设备
  changeDevice (row: DeviceInfo) {
    if (this.map) {
      const e: any = {
        target: {}
      }
      if (row.longitude && row.latitude) {
        e.target = this.map.getAllOverlays('marker').find((item: any) => {
          return item.detail.deviceNumber === row.deviceNumber
        })
        this.map.setCenter([Number(row.longitude), Number(row.latitude)])
      } else {
        this.iconTab()
        e.target = {
          detail: row
        }
      }
      this.drawerOpen(e)
    }
  }

  // 抽屉打开
  drawerOpen (e: any) {
    if ((this.drawerData === null || (this.drawerData && this.drawerData.deviceNumber !== e.target.detail.deviceNumber))) {
      if (this.drawerData && this.drawerData.deviceNumber !== e.target.detail.deviceNumber) {
        this.drawerClose()
      }
      if (e.target.detail.deviceNumber) {
        // 点击切换大图标
        const iconClick = this.getIcon(true)
        if (e.target.detail.longitude && e.target.detail.latitude) {
          e.target.setOffset(new AMap.Pixel(-22, -64))
          e.target.setIcon(iconClick)
        }
        this.drawerData = e.target.detail
        if (this.drawerData && this.drawerData.ezopenLive) {
          this.playVideo(this.drawerData.ezopenLive)
        }
      }
    }
    this.drawer = true
  }

  // 播放视频
  playVideo (address: string) {
    this.videoHtml = `<video
                id="videoPlay"
                ref="videoPlayer"
                class="video-js vjs-default-skin vjs-big-play-centered"
                controls
                autoplay
                muted
                preload="auto"
                style="width: 100%;height: 100%;"
              >
                <source src="${address}" type="application/x-mpegURL" />
              </video>`
    const options: any = {
      bigPlayButton: false,
      textTrackDisplay: false,
      posterImage: true,
      errorDisplay: false,
      controlBar: true
    }
    this.$nextTick(() => {
      this.videoPlay = videojs('videoPlay', options)
      this.videoPlay.play()
    })
  }

  // 获取图片
  getIcon (isCurrent: boolean) {
    if (!isCurrent) {
      return this.onlineImg
    } else {
      return this.onlineImgCursor
    }
  }

  // 抽屉关闭
  drawerClose () {
    console.log(this.drawer)
    if (this.drawer) {
      this.drawer = false
      // 清空抽屉
      this.iconTab()
      this.clearVideo()
      this.drawerData = null
    }
  }

  clearVideo () {
    this.videoPlay && this.videoPlay.dispose()
    this.videoHtml = ''
  }

  // 替换图标
  iconTab () {
    if (this.map && this.drawerData) {
      const deviceNumber = this.drawerData.deviceNumber || ''
      const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.deviceNumber === deviceNumber)
      const icon = this.getIcon(false)
      const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
      if (this.drawerData.longitude && this.drawerData.latitude) {
        marker.setOffset(new AMap.Pixel(-18, -48))
        marker.setIcon(icon)
      }
    }
  }
}
